import axios from 'axios';

export const loadTrackingGeneratedConfig = async (baseUrl, sessionId, target, trackingtoken, dealer) => {
  try {
    const domain = window.location.hostname;
    const result = await axios.get(`${baseUrl}/customizer/${sessionId}/generatedconfig?target=${target}&domain=${domain}&trackingtoken=${trackingtoken}${dealer ? '&dealer=' + dealer : ''}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
}
