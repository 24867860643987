import axios from 'axios';

export const loadConfigurationForSession = async (baseUrl, sessionId, target, trackingtoken, dealer) => {
  try {
    const domain = window.location.hostname.replace(/^www\./, '');
    const result = await axios.get(`${baseUrl}/customizer/session/${sessionId}/generatedconfig?target=${target}&domain=${domain}&trackingtoken=${trackingtoken}${dealer ? '&dealer=' + dealer : ''}`);
    return result.data;
  } catch (err) {
    console.log(err);
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
}

export const loadConfigurationForProfile = async (baseUrl, profileId, target, trackingtoken, dealer) => {
  try {
    const domain = window.location.hostname.replace(/^www\./, '');
    const result = await axios.get(`${baseUrl}/customizer/profile/${profileId}/generatedconfig?target=${target}&domain=${domain}&trackingtoken=${trackingtoken}${dealer ? '&dealer=' + dealer : ''}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
}

export const loadConfigurationForFavorite = async (baseUrl, favId, target, trackingtoken, dealer) => {
  try {
    const domain = window.location.hostname.replace(/^www\./, '');
    const result = await axios.get(`${baseUrl}/customizer/popular/${favId}/generatedconfig?target=${target}&domain=${domain}&trackingtoken=${trackingtoken}${dealer ? '&dealer=' + dealer : ''}`);
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
}
